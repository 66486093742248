import * as R from 'ramda'
import { replace } from '@upvestcz/common/routing'
import {
    BankIDBankSelectionButton,
    Link,
    Text,
    Box,
    Stack,
    Heading,
    GoogleAuthButton,
} from '@upvestcz/shared-components'
import Head from 'next/head'
import { Trans, useTranslation } from 'react-i18next'
import { BANK_ID_SCOPE_TYPES } from '@upvestcz/common/auth'
import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { NextPageWithData } from '../lib/next'
import { requireNonAuth } from '../lib/auth'
import { getAuthLayout } from '../components/layouts/AuthLayout'
import LoginForm from '../components/LoginForm'

const Login: NextPageWithData = () => {
    const { t } = useTranslation()

    const handleRegisterClick = useCallback(() => {
        ReactGA.event('register_intent', {
            source: 'login',
        })
    }, [])

    return (
        <>
            <Head>
                <title>{t('Přihlášení')}</title>
                <meta
                    name="description"
                    content={t('Přihlášení klienta k investiční platformě Upvest.')}
                />
            </Head>
            <Box maxW="400px" mx="auto" my="auto">
                <Stack spacing={10}>
                    <Heading as="h1" size="lg">
                        {t('Přihlásit se')}
                    </Heading>
                    <LoginForm />
                    <Stack spacing={4}>
                        <BankIDBankSelectionButton
                            redirectUri="/login-callback"
                            scopeType={BANK_ID_SCOPE_TYPES.LOGIN}
                            fallbackUri="/login"
                        >
                            {t('Přihlásit se přes BankID')}
                        </BankIDBankSelectionButton>
                        <GoogleAuthButton relativeRedirectUri="/login-callback">
                            {t('Přihlásit se přes Google')}
                        </GoogleAuthButton>
                    </Stack>
                    <Text color="textSecondary">
                        <Trans t={t}>
                            Pokud účet nemáte,{' '}
                            <Link color="primary" to="/signup" onClick={handleRegisterClick}>
                                zaregistrujte se
                            </Link>
                        </Trans>
                    </Text>
                </Stack>
            </Box>
        </>
    )
}

Login.getLayout = getAuthLayout

Login.getInitialProps = ctx => {
    const { query } = ctx

    // handle legacy urls and redirect them
    if (query.signup === 'true') {
        return replace({
            ctx,
            location: '/signup',
            forwardParams: false,
            push: false,
        })
    }

    return {}
}

export default R.compose(requireNonAuth)(Login)
