import { Button, ButtonProps } from '@chakra-ui/react'
import { bankIdScopeType } from '@upvestcz/common/auth'
import { useRouter } from 'next/router'
import { isNotUndefined, MergeChakraProps } from '@upvestcz/common/ts-utils'
import * as R from 'ramda'
import BankIDLogo from './BankIDLogo'
import Link from '../Link'

type BankIDBankSelectionButtonProps = MergeChakraProps<
    {
        children: React.ReactNode
        redirectUri?: string
        scopeType?: bankIdScopeType
        directlyCallBankID?: boolean
        fallbackUri?: string
    },
    ButtonProps
>

export const BankIDBankSelectionButton = ({
    children,
    redirectUri,
    scopeType,
    directlyCallBankID,
    fallbackUri,
    ...props
}: BankIDBankSelectionButtonProps) => {
    const router = useRouter()

    return (
        <Button
            data-test-id="bankid-auth-button"
            as={Link}
            variant="solidWhite"
            w="100%"
            onClick={() => {
                router.push({
                    pathname: '/bank-selection',
                    query: R.pickBy(isNotUndefined, {
                        redirectUri,
                        scopeType,
                        directlyCallBankID,
                        fallbackUri,
                    }),
                })
            }}
            leftIcon={<BankIDLogo height="14px" mb="2px" fill="#000" />}
            {...props}
        >
            {children}
        </Button>
    )
}
